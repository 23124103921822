<template>
  <router-view></router-view>
</template>

<script>


export default {
	name: 'Layout',
	components: {
	},
	setup(props, {emit}) {
		return {};
	}
}
</script>
